import React from 'react';
import clsx from 'clsx';
import styles from '../cookies.module.css';
import Layout from 'components/layout';
import Footer from 'components/layout/footer';
import { meta } from 'metaData';
import { sectionRender } from '../sectionRender';

const responsibilityList = [
  [
    'Policies:  ',
    'The Management Board Members are responsible for ensuring all Company policies are fully compliant with hiring/employment legislation and that all policies are reviewed at least every two years.',
  ],
  [
    'Risk assessments: ',
    'These will be conducted by the chief operating officer with support as required from the human resources department.',
  ],
  [
    'Investigations: ',
    'It will be the responsibility of the chief operating officer to investigate any concerns raised, whether these concerns are communicated directly to the role holder or via the anonymous suggestions system which the Company operates with support as required, by the Human Resources department.',
  ],
];

const relevantPoliciesList = [
  [
    'Anonymous Suggestions System: ',
    'The Company encourages all its workers to report any concerns related to the direct activities, or the supply chains of, the Company. This includes any circumstances that may give rise to an enhanced risk of slavery or human trafficking. The Company’s Anonymous Suggestions System is designed to make it easy for workers to make disclosures, without fear of retaliation.',
  ],
  [
    'Company Handbook: ',
    'The Company Handbook makes clear to employees the actions and behavior expected of them when representing the Company. Brocoders strives to maintain the highest standards of employee conduct and ethical behavior throughout its business and in managing its supply chain. The guidelines detailed in the code of conduct apply to all Company personnel, whether permanent or on fixed-term contracts and including agency staff, contractors, and workers employed via distributors to deliver services to the Company.',
  ],
  [
    'Recruitment of workers policy: ',
    'The Company uses only specified, reputable employment agencies to source labor and always verifies the practices of any new agency it is using before accepting workers from that agency.',
  ],
  [
    'Dignity at Work: ',
    'The Company encourages and maintains a professional and friendly working environment where everyone is free to work without harassment, victimization, or bullying for any reason and where everyone in the workplace is treated with dignity and respect. The policy, which is laid out in our Company Employee Handbook, applies to all workers, job applicants, agency employees, contractors, consultants, suppliers, and customers.',
  ],
  [
    'Anti-Bribery and Corruption:',
    'Brocoders is committed to the highest standards of ethical conduct and integrity in its business activities in the EU, and overseas. Brocoders will not tolerate any form of bribery or corruption by, or of, its employees, agents or consultants, contractors, or any person or body acting on its behalf.',
  ],
  [
    'Equality and Diversity:',
    'Brocoders is committed to being an equal opportunity employer and to ensuring that all employees, job applicants, customers/clients, and other people with whom we deal are treated fairly and are not subjected to unfair or unlawful discrimination.',
  ],
];

const diligenceProcessesList = [
  'Work closely with our supply chains to ensure compliance with legislative obligations and we require these organizations to have suitable anti-slavery and human trafficking policies and processes.',
  'Seek to build long-standing relationships with suppliers and to make clear our expectations of ethical business behavior from them.',
  'Have in place systems to encourage the reporting of concerns under our Anonymous Suggestions System.',
  'Monitor potential risk areas in our supply chain.',
];

const firstSection = [
  {
    type: 'paragraphs',
    content: [
      'Brocoders recognizes that slavery and human trafficking can occur in many forms including child labor, compulsory labor, freedom of association, discrimination, disciplinary practices, working hours, and remuneration. We have taken and will continue to take, a number of steps to endeavor to ensure that modern slavery is not taking place anywhere in our own business or in any of our supply chains. We are committed to acting ethically, with integrity and transparency in all business dealings. Brocoders knows that all businesses have an obligation to prevent slavery and human trafficking. Every company must do its share to eradicate this modern-day scourge.',
    ],
  },
];

const ourBusiness = [
  { type: 'subtitle', content: 'Our Business' },
  {
    type: 'paragraphs',
    content: [
      'Brocoders works closely with international IT companies, service providers, and startups in over 30 countries worldwide.',
      'The Company is legally compliant with all relevant employment legislation. Brocoders is committed to maintaining and improving systems and our processes to avoid complicity in human rights violations related to our own operations and supply chain.',
      'Where a country is deemed high risk the Company will seek assurances from its suppliers in that country that all workers have appropriate employment or service-providing contracts, are of legal working age and are paid compensation commensurate with the work that they do and the relevant practices in the country where that work is carried out.',
    ],
  },
];

const ourSupplyChain = [
  { type: 'subtitle', content: 'Our Supply Chain' },
  {
    type: 'paragraphs',
    content: [
      "We have assigned responsible persons and sales managers that have direct responsibility for supply chain management and acceptance of our supplier's credentials.",
      'Effective 1st January 2022, our  Company requires suppliers to acknowledge full compliance with the requirements of the European Convention on Human Rights, 1950, EU Trafficking Directive, 2011, UK Modern Slavery Act, 2015, or California Transparency in Supply Chains Act, 2010. Suppliers are required to supply us with a copy of their own statement signed by an authorized signatory (or refer us to here this is on their website) or, where this is not available, to sign a statement supplied by Brocoders to indicate that their supply chain is compliant with the main provisions of the acts above.',
      'No supplier contracts will be awarded to any supplier that is not able to comply with at least one of the mentioned here acts. Any breach of such obligation by a supplier will result in dialogue with that supplier and potentially the termination of that supply contact.',
    ],
  },
];

const responsibility = [
  { type: 'subtitle', content: 'Responsibility' },
  {
    type: 'paragraphs',
    content: ["Responsibility for the Company's anti-slavery initiatives is as follows:"],
  },
  {
    type: 'highlighted list',
    content: responsibilityList,
  },
];

const relevantPolicies = [
  { type: 'subtitle', content: 'Relevant Policies' },
  {
    type: 'paragraphs',
    content: [
      "In keeping with the Company's commitment to act with integrity in all its business dealings, many of our existing policies are relevant to ensure that there is no modern slavery or trafficking in any part of the business.",
      'Our relevant policies include:',
    ],
  },
  {
    type: 'highlighted list',
    content: relevantPoliciesList,
  },
];

const diligenceProcesses = [
  { type: 'subtitle', content: 'Due Diligence Processes for Slavery and Human Trafficking' },
  {
    type: 'paragraphs',
    content: [
      'As part of our commitment to identify and eradicate slavery and human trafficking from within our business and from those businesses with which we interact, we - ',
    ],
  },
  {
    type: 'list',
    content: diligenceProcessesList,
  },
];

const educationAndTraining = [
  { type: 'subtitle', content: 'Education and Training' },
  {
    type: 'paragraphs',
    content: [
      'To ensure appropriate levels of risk awareness in respect of modern slavery and human trafficking, we provide annual training to relevant employees and management in all departments.',
      'The Human Resources department has the responsibility for ensuring that all employees are aware of the Company’s statement of compliance with the anti-slavery legislation and its ongoing commitments.',
    ],
  },
];

const boardApproval = [
  { type: 'subtitle', content: 'Board Approval' },
  {
    type: 'paragraphs',
    content: [
      'This statement has been approved by the Company’s management board members and signed by the CEO of Brocoders, who will ensure it is reviewed annually and published.',
    ],
  },
  {
    type: 'custom',
    content: () => (
      <div className={styles.signBlock}>
        <p className={styles.sign}>Andrii Konoplenko</p>
        <p className={styles.sign}>CEO</p>
        <p className={styles.sign}>
          <b>Date: </b>21st October 2022
        </p>
      </div>
    ),
  },
];

const pageSections = [
  firstSection,
  ourBusiness,
  ourSupplyChain,
  responsibility,
  relevantPolicies,
  diligenceProcesses,
  educationAndTraining,
  boardApproval,
];

function SlaveryStatement() {
  return (
    <Layout isRed metaData={{ main: meta.cookies }}>
      <main className={clsx('inner-container', styles.main)}>
        <h1 className={styles.title}>Brocoders&rsquo; Modern Slavery Statement</h1>
        {pageSections.map((item) => sectionRender(item))}
      </main>
      <Footer />
    </Layout>
  );
}

export default SlaveryStatement;
